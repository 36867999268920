<template>
    <el-dialog :title="'下载文件'" :close-on-click-modal="false" :visible.sync="subVisible" width="700px">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="文件编号" prop="fileNo">
                        {{ dataForm.fileNo }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="margin-left: 40px;">
                <el-col :span="24" v-if="dataForm.fileUri">
                    <span class="tmp" id="tmp">{{ dataForm.fileName }}</span>
                    <el-button v-preventReClick type="text" size="small" style="margin-left: 150px;"
                        @click="downloadFile(dataForm.fileUri, dataForm.fileName)">下载</el-button>
                </el-col>
            </el-row>
            <el-row v-for="(file, index) in dataForm.fileList" style="margin-left: 40px;">
                <el-col :span="24" v-if="dataForm.fileList">
                    <span class="tmp" id="tmp">{{ file.fileName }}</span>
                    <el-button v-preventReClick type="text" size="small" style="margin-left: 150px;"
                        @click="downloadFile(file.filePathUrl, file.fileName)">下载</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-divider></el-divider>
    </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
    name: "contractdocument-files-view",
    data() {
        return {
            subVisible: true,
            action: "",
            dataForm: {
                fileNo: '',
                fileList: [],
                fileId: '',
                fileName: '',
                filePath: '',
                fileUri: ''
            },
            dataRule: {
            }
        };
    },
    components: {

    },
    activated() {

    },
    methods: {
        init(id) {
            this.dataForm = {
                fileNo: '',
                fileList: [],
                fileId: '',
                fileName: '',
                filePath: '',
                fileUri: ''
            }
            this.dataForm.fileId = id
            this.getDetail();
            this.$nextTick(() => {
                this.subVisible = true;
            })
            this.$refs['dataForm'].resetFields();
        },
        downloadFile(url, filename) {
            filename = this.getSuffix(url, filename)
            this.getBlob(url, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },

        getDetail() {
            this.$http({
                url: this.$store.state.httpUrl + `/business/supervisioncontactinfo/info/${this.dataForm.fileId}`,
                method: "get",
                params: {}
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm = {
                        fileId: data.body.fileId,
                        fileNo: data.body.fileNo,
                        fileName: data.body.fileName,
                        fileUri: data.body.fileUri,
                        filePath: data.body.filePath,
                        fileList: data.body.fileList
                    };
                }
            });
        },
    }
};
</script>

<style lang="scss" scoped="scoped">
.tmp {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 248px;
    display: inline-block;
    color: #00428e;
    margin-top: 13px;
}
</style>
