<template>
	<!-- 监理发文 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">{{ fileKindName }}</div>
			<div class="line"></div>
			<el-row
				style="margin-top: -8px;margin-left: -20px;line-height:30px;width: 103%;">
				<el-row style="margin-top: 10px;">
					<el-col :span="2">
						<div class="search_title">标的物</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(subject, index) in subjectList" style="margin-right: 50px;">
							<span class="search_bn_border" v-if="form.subjectNo === subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
							<span class="search_bn" v-else-if="form.subjectNo !== subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: 10px;"></div>
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
					<el-col :span="11" style="margin-left:15px;margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="addOrUpdateHandle()"
							v-if="showAdd">新增</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="disabledBatchDelete"
							@click="deleteBatch()">删除</el-button>
					</el-col>
					<el-col :span="12">
						<div style="float: right;margin-right: 15px;vertical-align:middle;">
							<span style="font-size: 16px; font-weight: bold;vertical-align: middle">已封闭 </span>
							<el-tag size="medium" type="success" style="font-size: 16px">{{ dataTotal.closeNum }} 份</el-tag>
							<span style="font-size: 16px; font-weight: bold;vertical-align: middle">未封闭 </span>
							<el-tag size="medium" type="danger" style="font-size: 16px;">{{ dataTotal.nonCloseNum }}
								份</el-tag>
						</div>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: -2px;"></div>
				<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
					<el-row
						style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
						<el-col :span="18" style="margin-left:20px">
							<!-- <el-form-item label="标的物" class="selFormItem">
								<el-select class="selSubjectInput" v-model="form.subjectNo" placeholder="请选择" clearable>
									<el-option v-for="item in $store.state.common.navBarSubjectList"
										:key="item.displayValue" :label="item.displayName" :value="item.displayValue">
									</el-option>
								</el-select>
							</el-form-item> -->
							<el-form-item label="文件编号" class="selFormItem">
								<el-input class="selSearchInput" v-model="form.fileNo" clearable></el-input>
							</el-form-item>
							<el-form-item label="封闭状态" class="selFormItem">
								<el-select class="selMajorInput" v-model="form.closeFlg" placeholder="请选择" clearable>
									<el-option v-for="item in closeFlgList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="签发时间" class="selFormItem" style="margin-top:2px">
								<el-date-picker class="selDateInput" v-model="dispTime" type="daterange"
									value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期"
									end-placeholder="结束日期" clearable>
								</el-date-picker>
							</el-form-item>
							</el-col>
							<el-col :span="4" class="selBtnGroup">
							<el-button style="margin-top:5px" v-preventReClick class="btn" type="primary" size="small"
								@click="selGetDataList()">查询</el-button>
							<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 490px;" body-style="height:450px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="430" :row-style="selectRowBackground" class="dataListTable" :data="dataList"
								header-align="center" style="width: 100%; margin-bottom: 10px;" border
								:row-class-name="tableRowClassName" header-cell-class-name="dataListTHeader"
								@selection-change="handleSelectionChange" :cell-style="{ padding: '0px' }">
								<el-table-column type="selection" width="45" align="center"></el-table-column>
								<el-table-column label="NO." type="index" width="60" align="center" fixed="left">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<!-- <el-table-column prop="subjectName" label="标的物" width="100" align="center" fixed="left">
								</el-table-column> -->
								<el-table-column prop="fileNo" label="文件编号" width="180" align="center" fixed="left">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="viewPdf(scope.row.filePath, scope.row.fileUri, scope.row.fileName)">{{
												scope.row.fileNo }}</el-button>
									</template>
								</el-table-column>
								<el-table-column prop="dispObject" label="发文对象" align="left" show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="fileTitle" label="主要内容" align="left" show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="dispTime" label="签发时间" width="120" align="center">
								</el-table-column>
								<!-- <el-table-column prop="handleOptions" label="处理意见及跟踪情况" width="400" align="left"
								show-overflow-tooltip>
							</el-table-column> -->
								<!-- <el-table-column label="封闭状态" width="100" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.closeFlg === '0'" style="color: #e7b243;">处理中</span>
									<span v-if="scope.row.closeFlg === '1'">已封闭</span> -->
								<!-- <span v-if="scope.row.isCloseHandle==='0'">无需封闭</span> -->
								<!-- </template>
							</el-table-column> -->
								<el-table-column prop="dispMan" label="工程师 " width="110" align="center">
								</el-table-column>
								<el-table-column prop="closeTime" label="封闭时间 " width="110" align="center">
								</el-table-column>
								<el-table-column prop="closeMan" label="封闭人" width="120" align="center">
								</el-table-column>
								<el-table-column prop="remark" label="备注" width="120" align="center" show-overflow-tooltip>
								</el-table-column>
								<el-table-column label="操作" width="120" v-if="showEdit || showDelete || showDownload"
									align="center" fixed="right">
									<template slot-scope="scope">
										<!-- <el-button v-preventReClick type="warning" size="small"
										@click="auditeHandle(scope.row.fileId)"
										v-if="scope.row.closeFlg === '0'">处理意见</el-button> -->
										<!-- <el-button v-preventReClick type="text" size="small"
										@click="viewFilesHandle(scope.row.fileId)">查看</el-button> -->
										<el-button v-preventReClick type="text" size="small"
											@click="addOrUpdateHandle(scope.row.fileId)" v-if="showEdit">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="uploadContactHandle(scope.row)">上传</el-button>
										<!-- <el-button v-preventReClick type="text" size="small"
										@click="closeHandle(scope.row.fileId)">封闭</el-button> -->
										<!-- <el-button v-preventReClick type="text" size="small"
										@click="showCloseFileHandle(scope.row.fileId)"
										v-if="showDelete && scope.row.closeFlg === '1'">封闭文件</el-button> -->
										<!-- <el-button v-preventReClick type="text" size="small"
										@click="deleteHandle(scope.row.fileId)"
										v-if="showDelete && scope.row.closeFlg !== '1'">删除</el-button> -->
										<el-button v-preventReClick type="text" size="small"
										@click="downloadFile(scope.row.fileUri, scope.row.fileName)"
										v-if="showDownload && scope.row.fileUri">下载</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>

		<el-dialog title="文件上传" :visible.sync="uploadContactVisible" width="40%" :close-on-click-modal="false">
			<el-form>
				<el-row>
					<el-col :span="6">
						<div class="item">
							文件
						</div>
					</el-col>
					<el-col :span="14">
						<el-upload v-if="!uploadFile.uploadFilePath" ref="uploadScanFile" class="upload-demo" action="action"
						drag :http-request="uploadScanFile" :show-file-list=false :auto-upload="true" style="margin-left: 10px;">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						</el-upload>
						<div v-if="uploadFile.uploadFilePath" style="margin-left: 10px;">
							<el-button v-preventReClick type="success" size="small" @click="downloadFile(
							uploadFile.uploadFileUri, uploadFile.uploadFileName)">查看</el-button>
							<el-button v-preventReClick type="primary" size="small" @click="delFile()">删除</el-button>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="uploadContactFile()">保存</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="uploadContactVisible = false">取 消</el-button>
			</span>
		</el-dialog>
		
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
		<audit v-if="auditVisible" ref="audit" @refreshDataList="getDataList"></audit>
		<document-close v-if="closeVisible" ref="documentClose" @refreshDataList="getDataList"></document-close>
		<close-file v-if="closeFileVisible" ref="closeFile" @refreshDataList="getDataList"></close-file>
		<document-files-view v-if="filesViewVisible" ref="documentFilesView"
			@refreshDataList="getDataList"></document-files-view>
		<view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
	</div>
</template>
<script>
import AddOrUpdate from './contactdocument-add-or-update'
import audit from './contactdocument-audit'
import DocumentClose from './contactdocument-close'
import DocumentFilesView from './contractdocument-files-view.vue'
import CloseFile from './contactdocument-close-flie'
import $common from "@/utils/common.js"
import ViewPdf from "../projectInfo/viewPdf.vue"
export default {
	data() {
		return {
			showSearch: true,
			showFileKindSet: true,
			viewPdfVisible: false,
			showReply: true,
			showAdd: true,
			showEdit: true,
			showDelete: true,
			showCheck: true,
			showArchive: true,
			showDownload: true,
			pageSpan: 12,
			tabIndex: "0",
			addOrUpdateVisible: false,
			auditVisible: false,
			closeVisible: false,
			closeFileVisible: false,
			disabledBatchDelete: true,
			filesViewVisible: false,
			dataTotal: {
				closeNum: 0,
				nonCloseNum: 0
			},
			form: {
				fileKind: '01',
				closeFlg: '',
				subjectNo: '',
				fileNo: ''
			},
			closeFlgList: [
				{
					label: '未封闭',
					value: '0'
				}, {
					label: '已封闭',
					value: '1'
				}
			],
			fileKindName: '',
			documentTypeList: [],
			fileNo: "",
			fileTitle: "",
			dispMan: "",
			dispTime: [],
			deceDispCompany: "",
			exaFlg: "",
			answerFinishFlg: "",
			answerFinishFlgName: "",
			dataList: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			tableSelVal: [],
			subjectList: [],
			uploadFileId: '',
			uploadFileName: '',
			uploadContactVisible: false,
			uploadFile : {
				uploadFilePath : '',
				uploadFileUri: '',
				uploadFileName: ''
			}
		};
	},
	components: {
		AddOrUpdate,
		audit,
		DocumentClose,
		CloseFile,
		DocumentFilesView,
		ViewPdf
	},
	mounted() {
		this.form.fileKind = this.$route.query.type;
		this.fileKindName = '监理联系单'
		if (this.form.fileKind === '02') {
			this.fileKindName = '监理通知单'
		}
		// this.showSearch = $common.isAuth('superdocument:search');
		// this.showFileKindSet = $common.isAuth('superdocument:file:kind:set');
		// this.showAdd = $common.isAuth('superdocument:add');
		// this.showEdit = $common.isAuth('superdocument:edit');
		// this.showReply = $common.isAuth('superdocument:reply');
		// this.showDelete = $common.isAuth('superdocument:delete');
		// this.showCheck = $common.isAuth('superdocument:check');
		// this.showArchive = $common.isAuth('superdocument:archive');
		// this.showDownload = $common.isAuth('superdocument:download');
		if (!this.showFileKindSet && !this.showAdd) {
			this.pageSpan = 24;
		}
		this.getSubjectDropDown();
		// this.getDataList();
		// this.getSubjectDropDown()
	},
	methods: {
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					if (this.subjectList != undefined && this.subjectList.length > 0) {
						this.form.subjectNo = this.subjectList[0].displayValue
						this.getDataList();
					}
				}
			});
		},
		setSubject(subject) {
			this.form.subjectNo = subject.displayValue
			this.getDataList()
		},
		showCloseFileHandle(id) {
			this.closeFileVisible = true
			this.$nextTick(() => {
				this.$refs.closeFile.init(id)
			})
		},
		viewPdf(filePath, fileUri, filename) {
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
				this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
		downloadFile(fileUri, filename) {
			filename = this.getSuffix(fileUri, filename)
			this.getBlob(fileUri, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		countContact() {
			let startTime;
			let completeTime;
			if (this.dispTime != null) {
				startTime = this.dispTime[0] ? this.dispTime[0] : ""
				completeTime = this.dispTime[1] ? this.dispTime[1] : ""
			} else {
				startTime = ""
				completeTime = ""
			}
			this.$http({
				url: this.$store.state.httpUrl + "/business/supervisioncontactinfo/countContact",
				method: 'get',
				params: this.$http.adornParams({
					fileKind: this.form.fileKind,
					startTime: startTime,
					completeTime: completeTime,
					workNo: $common.getItem("workNo"),
					subjectNo: this.form.subjectNo,
					fileNo: this.form.fileNo,
					closeFlg: this.form.closeFlg,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataTotal = data.body
				}
			})
		},
		// 获取数据列表
		getDataList() {
			let startTime;
			let completeTime;
			if (this.dispTime != null) {
				startTime = this.dispTime[0] ? this.dispTime[0] : ""
				completeTime = this.dispTime[1] ? this.dispTime[1] : ""
			} else {
				startTime = ""
				completeTime = ""
			}
			if (this.showSearch) {
				this.countContact();
				this.$http({
					url: this.$store.state.httpUrl + "/business/supervisioncontactinfo/list",
					method: 'get',
					params: this.$http.adornParams({
						page: this.pageIndex,
						fileKind: this.form.fileKind,
						limit: this.pageSize,
						startTime: startTime,
						completeTime: completeTime,
						workNo: $common.getItem("workNo"),
						subjectNo: this.form.subjectNo,
						closeFlg: this.form.closeFlg,
						fileNo: this.form.fileNo
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex
					} else {
						this.dataList = []
						this.totalPage = 0
						this.pageIndex2 = 1
					}
				})
			}
		},
		//搜索
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		//重置
		resetFormData() {
			this.dispTime = [];
			this.form.closeFlg = '';
			// this.form.subjectNo = '';
			this.form.fileNo = '';
			this.getDataList();
		},
		//发文类型
		documentType(value) {
			this.fileKind2 = value;
			this.pageIndex = 1;
			this.getDataList();
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		viewFilesHandle(fileId) {
			this.filesViewVisible = true;
			this.$nextTick(() => {
				this.$refs.documentFilesView.init(fileId)
			})
		},
		//删除
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/supervisioncontactinfo/delete/" + id,
					method: "POST",
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.tableSelVal.map((item) => item.fileId);
				this.$http({
					url: this.$store.state.httpUrl + "/business/supervisioncontactinfo/deleteBatch",
					method: 'post',
					data: this.$http.adornData({
						records: records
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				})
			})
				.catch(() => { })
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
			if (val.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.fileId);
			if (checkIdList.includes(row.fileId)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
		// 审核
		auditeHandle(id) {
			this.auditVisible = true
			this.$nextTick(() => {
				this.$refs.audit.init(id)
			})
		},
		closeHandle(id) {
			this.closeVisible = true
			this.$nextTick(() => {
				this.$refs.documentClose.init(id)
			})
		},
		// 回复
		replyHandle(id) {
			this.replyVisible = true
			this.$nextTick(() => {
				this.$refs.reply.init(id)
			})
		},
		//新增
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id, this.form.fileKind, this.form.subjectNo)
			})
		},
		uploadContactHandle(row) {
			this.uploadFileId = row.fileId
			this.uploadFileName = row.fileName
			this.uploadFile = {
				uploadFilePath : row.filePath,
				uploadFileUri : row.fileUri,
				uploadFileName: row.fileName
			}
			this.uploadContactVisible = true
		},
		uploadScanFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadScanFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/20/" + this.form.fileKind,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data;
				if (res.resultCode === 200) {
					this.uploadFile = {
						uploadFilePath : res.body.fileSavePath,
						uploadFileUri: res.body.fileUri,
						uploadFileName: this.uploadFileName
					}
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		extractFileName(fileName) {
            let index = fileName.lastIndexOf(".");
            return fileName.substr(0, index)
        },
        selectContactFile(url, filename) {
            filename = this.getSuffix(url, filename)
            this.getBlob(url, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },
        delFile() {
            this.uploadFile = {
				uploadFilePath :  '',
				uploadFileUri : '',
			}
        },
		uploadContactFile() {
			if(this.uploadFile.uploadFilePath == undefined || this.uploadFile.uploadFilePath == '') {
				this.$message.error("请上传文件！")
				return
			}
			this.$http({
				url: this.$store.state.httpUrl + "/business/supervisioncontactinfo/uploadContactFile",
				method: 'post',
				data: this.$http.adornData({
					fileId: this.uploadFileId,
					filePath: this.uploadFile.uploadFilePath,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.uploadContactVisible = false;
							 this.uploadFile = {
								uploadFilePath :  '',
								uploadFileUri : '',
								uploadFileName : ''
							}
							this.getDataList()
						}
					})
				}
			})
		}
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

::v-deep(.el-table .cellgrey) {
	background: #d6e0eb !important;
	;
}

.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

::v-deep(.selSubjectInput .el-input__inner) {
	width: 120px !important;
	height: 30px !important;
}

::v-deep(.selSearchInput .el-input__inner) {
	width: 150px !important;
	height: 30px !important;
}

::v-deep(.selMajorInput .el-input__inner) {
	width: 110px !important;
	height: 30px !important;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}

::v-deep(.upload-demo .el-upload-dragger) {
	width: 200px;
	height: 80px;
}

::v-deep(.upload-demo .el-upload-dragger .el-icon-upload) {
	font-size: 30px;
	margin: 12px 1px;
	line-height: 20px;
}

::v-deep(.upload-demo .el-upload-dragger .el-upload__text) {
	line-height: 5px;
}

.item {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border: solid #dadada 1px;
	height: 35px !important;
	text-align: center;
	padding-top: 8px
}
</style>
